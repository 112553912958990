<template>
  <div class="main-container">
    <div>
      <h5 class="pageTitle">버전 정보</h5>
    </div>
    <mdb-container class="form-container">
      <p>앱버전</p>
      <mdb-input type="text" label="앱버전" outline class="m-1" v-model="appVersion"/>
      <br/>
      <p>웹버전</p>
      <mdb-input type="text" label="웹버전" outline class="m-1" v-model="webVersion"/>
    </mdb-container>
    <mdb-container class="text-right">
      <mdb-btn outline="primary" @click="putVersionInfo">수정</mdb-btn>
    </mdb-container>
  </div>

</template>

<script>
import {mdbContainer, mdbInput, mdbBtn} from "mdbvue";
import axios from "@/axios";

export default {
  name: "Version",
  components: {
    mdbContainer,
    mdbInput,
    mdbBtn
  },
  data() {
    return {
      token: this.$store.state.token.access_token,
      webVersion: '',
      appVersion: '',
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.getVersionInfo();
    },
    getVersionInfo() {
      const self = this;

      const config = {
        method: 'get',
        url: '/versions',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${self.token}`
        },
      };

      console.log('getVersionInfo');
      console.log(config);

      axios(config)
          .then(res => {
            self.appVersion = res.data.app
            self.webVersion = res.data.web
          })
    },
    putVersionInfo() {
      const self = this;
      if (self.webVersion.trim().length * self.appVersion.trim().length === 0) {
        self.$swal.fire({title: '입력 필수', text: '항목을 입력해 주세요.', icon: 'info'})
        return
      }


      const data = JSON.stringify({
        "web": self.webVersion,
        "app": self.appVersion
      });

      const config = {
        method: 'put',
        url: '/versions',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${self.token}`
        },
        data: data
      };

      console.log('putVersionInfo');
      console.log(config);

      axios(config)
          .then(() => {
            self.$swal.fire({title: '수정 완료', text: '버전 정보가 변경 되었습니다.', icon: 'success'})
          })

    }
  }
}
</script>

<style scoped>
.main-container {
  padding: 15px;
}

.pageTitle {
  font-weight: bold;
}

.form-container {
  margin-top: 30px;
}
</style>
